import React from "react";
import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Paper,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { colunasTabelaGmd } from "./balancoArrobaUtil";

const BalancoArrobaTabelaGmd = (props) => {
  const { classes, balancosFazenda } = props;

  return (
    <Paper className={classes.paperTabela}>
      <Table id="tabela_total" className={classes.tabelaGmd}>
        <TableHead className={classes.cabecalhoTabela}>
          <TableRow>
            {colunasTabelaGmd.map((coluna, key) => (
              <TableCell key={key} className={classes[coluna.style]}>
                <div className={classes.defaultCell}>
                  {`${coluna.label}  `}
                  {coluna.tooltip && (
                    <Tooltip
                      classes={{ tooltip: classes.tooltip }}
                      title={coluna.tooltip}
                      placement="top"
                    >
                      <InfoIcon
                        color={coluna.iconColor ? coluna.iconColor : "primary"}
                        className={classes.iconeInfoProdCab}
                        placement="right"
                      />
                    </Tooltip>
                  )}
                </div>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className={classes.dadosFechamento}>
              {balancosFazenda.RebanhoMedio}
            </TableCell>
            <TableCell className={classes.dadosFechamento}>
              {balancosFazenda.ProducaoEmQuilos}
            </TableCell>
            <TableCell className={classes.dadosFechamento}>
              {balancosFazenda.ProducaoEmArrobas}
            </TableCell>
            <TableCell className={classes.dadosFechamento}>
              {balancosFazenda.Periodo}
            </TableCell>
            <TableCell className={classes.ganhoMedioDiario}>
              {balancosFazenda.GMD}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
};

const styles = (theme) => ({
  paperTabela: {
    borderRadius: "16px",
  },
  tabelaGmd: {
    fontFamily: "Roboto",
    textAlign: "center",
    borderRadius: "16px",
    padding: "4px 24px 4px 24px",
    fontSize: "14px",
    width: "138,500px",
    height: "1472,470px",
  },
  cabecalhoTabela: {
    padding: "4px 24px 4px 24px",
    marginTop: "25px",
    marginBottom: "25px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    backgroundColor: "#e1e1e1",
    borderRadius: "16px",
  },
  cabecalhoTabelaConteudo: {
    color: theme.palette.primary.main,
    fontSize: "14px",
    fontWeight: "500",
    letterSpacing: "1px",
    textAlign: "center",
    padding: "4px 24px 4px 24px",
  },
  cabecalhoPrimeiraCelula: {
    color: theme.palette.primary.main,
    fontSize: "14px",
    fontWeight: "500",
    letterSpacing: "1px",
    textAlign: "center",
    padding: "4px 24px 4px 24px",
    borderRadius: "16px 0 0 0",
  },
  gmdCabecalho: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    fontSize: "14px",
    textAlign: "center",
    fontWeight: "500",
    letterSpacing: "1px",
    padding: "4px 24px 4px 24px",
    borderRadius: "0 16px 0 0",
  },
  iconeInfoProdCab: {
    fontSize: "14px",
    position: "relative",
  },
  dadosFechamento: {
    borderRight: "1px solid #e5e5ea",
    borderBottom: "none",
    padding: "4px 24px 4px 24px",
    textAlign: "center",
    fontSize: "18px",
    fontWeight: "500",
    lineHeight: "normal",
    color: "#666",
  },
  ganhoMedioDiario: {
    padding: "4px 24px 4px 24px",
    textAlign: "center",
    fontSize: "18px",
    fontWeight: "500",
    lineHeight: "normal",
    color: theme.palette.primary.main,
    borderLeft: "1px solid #e5e5ea",
    borderRadius: "0 0 0 16px",
  },
  defaultCell: {
    display: "flex",
    justifyContent: "center",
    height: "inherit",
    alignItems: "center",
  },
  tooltip: {
    fontSize: "16px",
    padding: "6px",
  },
});

export default withStyles(styles)(BalancoArrobaTabelaGmd);
